import React from "react"
import { graphql, Link } from "gatsby"
import "./index.scss"
import LeaveIcon from "@assets/svg/Birchbrook_Icon_Lavender.svg"
import { SanityClientAccess } from "@utils/globalTypes"
import SEO from "@components/SEO"
import { Row, Col, Image } from "react-bootstrap"

type Props = {
  data: {
    sanityClientAccess: SanityClientAccess
  }
  location: Location
}

const ClientAccessPage = ({
  data: { sanityClientAccess },
  location,
}: Props) => {
  const { title, backgroundColor, content } = sanityClientAccess
  const metadata = {
    location: location.href,
    ...sanityClientAccess?.seo,
  }

  return (
    <>
      <SEO {...metadata} />
      <section
        className="ca-wrapper content-padding-top"
        style={{ backgroundColor: backgroundColor || "#DED9E5" }}
      >
        <h2> {title} </h2>
        <Row className="buttons-container">
          {content &&
            content.map((item, index) => (
              <Col
                key={index}
                md={6}
                sm={12}
                xs={12}
                className={`${
                  index % 2
                    ? "buttons-container-last"
                    : "buttons-container-first"
                }`}
              >
                {item?.linkUrl?.charAt(0) === "/" ? (
                  <Link to={item.linkUrl} className="ca-cta-button">
                    <>
                      {" "}
                      <Image src={LeaveIcon} alt="birchbrook" />
                      {item.title}{" "}
                    </>
                  </Link>
                ) : (
                  <a
                    href={item.linkUrl}
                    target="_blank"
                    rel="noopener"
                    className="ca-cta-button"
                  >
                    <>
                      {" "}
                      <Image src={LeaveIcon} alt="birchbrook" /> {item.title}{" "}
                    </>
                  </a>
                )}{" "}
              </Col>
            ))}
        </Row>
      </section>
    </>
  )
}

export default ClientAccessPage

export const query = graphql`
  query sanityClientAccess($slug: String) {
    sanityClientAccess(slug: { current: { eq: $slug } }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      title
      slug {
        current
      }
      backgroundColor
      content {
        linkUrl
        title
        _type
      }
    }
  }
`
